import React, { useEffect, useState } from "react";
import axios from "axios";
import { decrypt } from "../lib/aes";
import hash from "hash.js";
import { Button, styled, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
const server = process.env.REACT_APP_server || "https://api-us.wcyat.me";
/** white text field */
const WhiteTextField = styled(TextField)({
  "& label": {
    color: "white",
  },
  "&:hover label": {
    fontWeight: 700,
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});
export default function Redirect() {
  const params = useParams();
  const id = params.id;
  const [password, setPassword] = useState("");
  const [text, setText] = useState("Please wait...");
  const [encrypted, setEncrypted] = useState(false);
  function decrypturl() {
    setText("Please wait...");
    axios
      .post(`${server}/check`, {
        password: hash.sha256().update(password).digest("hex"),
        id: id,
      })
      .then((res) => {
        setEncrypted(false);
        window.location.replace(
          decrypt(res.data.url, hash.sha512().update(password).digest("hex"))
        );
      })
      .catch((err) => {
        setText(err.response.data);
      });
  }
  useEffect(() => {
    axios
      .get(`${server}/${id}`)
      .then((res) => {
        if (!res.data.encrypted) {
          window.location.replace(res.data.url);
        } else {
          setEncrypted(true);
          setText("This url is encrypted. Enter password to decrypt.");
        }
      })
      .catch((err) => {
        setText(err.response.data);
      });
  }, [id]);
  return (
    <div className="ml20">
      <h1 className="white">{text}</h1>
      {encrypted && (
        <WhiteTextField
          color="secondary"
          type="password"
          label="Password"
          variant="outlined"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      )}
      <br />
      <Button
        color="secondary"
        className="mt20"
        variant="contained"
        onClick={decrypturl}
      >
        Decrypt
      </Button>
    </div>
  );
}
