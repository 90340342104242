import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./index.css";
import "./css/margin.css";
import "./css/common.css";
import Home from "./pages";
import Redirect from "./pages/redirect";
import History from "./pages/history";
import ResponsiveAppbar from "./components/Appbar";
declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#020546",
    },
    secondary: {
      main: "#ffc107",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Box sx={{ height: "100vh", backgroundColor: "primary.dark" }}>
      <Router>
      <ResponsiveAppbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/:id" element={<Redirect />} />
        </Routes>
      </Router>
    </Box>
  </ThemeProvider>,
  document.getElementById("root")
);
