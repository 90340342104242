import "./css/index.css";
import React, { useState } from "react";
import hash from "hash.js";
import { encrypt as aesencrypt} from "../lib/aes";
import axios from "axios";
import isUrlHttp from "is-url-http";
import "../App.css";
import {
  TextField,
  Box,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
const server = process.env.REACT_APP_server || "https://api-us.wcyat.me";
const redirectserver = process.env.REACT_APP_redirectserver || "https://l.wcyat.me";
function transform(x: string) {
  function checkhasprotocol() {
    try {
      new URL(x);
      return true;
    } catch {
      return false;
    }
  }
  if (
    !x.startsWith("https://") &&
    !x.startsWith("http://") &&
    !checkhasprotocol()
  ) {
    x = `https://${x}`;
  }
  return x;
}
export default function Home() {
  const [link, setLink] = useState("");
  const [password, setPassword] = useState("");
  const [warning, setWarning] = useState("");
  const [slink, setSlink] = useState("");
  const [wait, setWait] = useState("");
  const [encrypt, setEncrypt] = useState(Boolean(localStorage.encrypt));
  function save(slink: string, olink: string) {
    if (!localStorage.history) {
      localStorage.history = JSON.stringify({
        1: {
          slink: slink,
          olink: olink,
        },
      });
    } else {
      const history = JSON.parse(localStorage.history);
      history[Object.keys(history).length + 1] = {
        slink: slink,
        olink: olink,
      };
      localStorage.history = JSON.stringify(history);
    }
  }
  function shorten() {
    const sendlink = transform(link);
    setSlink("");
    setWarning("");
    if (encrypt && !password) {
      setWarning("Password cannot be empty.");
      return;
    }
    const serverpwd = hash.sha256().update(password).digest("hex");
    const encryptpwd = hash.sha512().update(password).digest("hex");
    const encryptedurl = aesencrypt(link, encryptpwd);
    if (isUrlHttp(sendlink)) {
      setWait("Please wait...");
      axios
        .post(
          `${server}/create`,
          encrypt
            ? {
                encrypted: true,
                url: encryptedurl,
                password: serverpwd,
              }
            : { url: sendlink }
        )
        .then((res) => {
          const slink = `${encrypt ? window.location.origin : redirectserver}/${res.data.id}`;
          setSlink(slink);
          setWait("");
          navigator.clipboard.writeText(slink);
          save(slink, sendlink);
        })
        .catch((err) => {
          setWarning(err.response.data);
          setWait("");
        });
    } else {
      setWarning("Invalid url.");
    }
  }
  return (
      <Box
        className="flex flex-dir-column min-height-full index-root"
        sx={{
          bgcolor: "primary.dark",
        }}
      >
        <h1 className="white ml50">Url shortener</h1>
        <Box
          className="ml50 mr50 flex align-center"
          sx={{
            bgcolor: "secondary.main",
          }}
        >
          <Box className="m50 fullwidth">
            <h2>Enter url:</h2>
            <TextField
              label="link"
              variant="standard"
              onChange={(e) => {
                setLink(e.target.value);
                if (warning === "Invalid url." && isUrlHttp(transform(link)))
                  setWarning("");
              }}
              fullWidth
            />
            <FormGroup>
              <FormControlLabel
                className="mt10"
                control={
                  <Switch
                    color="primary"
                    onChange={(e) => {
                      setEncrypt(e.target.checked);
                      if (e.target.checked)
                        localStorage.encrypt = true;
                      else
                        localStorage.removeItem("encrypt");
                    }}
                    checked={encrypt}
                  />
                }
                label="Encrypt url with a password"
              />
            </FormGroup>
            {encrypt && (
              <TextField
                type="password"
                label="password"
                variant="outlined"
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (warning === "Password cannot be empty." && password)
                    setWarning("");
                }}
              />
            )}
            {warning && <br/>}
            {warning && <span className="red">{warning}</span>}
            {(encrypt || warning) && <br/>}
            <Button
              onClick={shorten}
              className="mt10"
              variant="outlined"
            >
              Shorten
            </Button>
            <br />
            <p>{wait}</p>
            {slink && (
              <p>
                Shortened link (Copied to clipboard): 
                <a href={slink} target="_blank" rel="noreferrer">{slink}</a>
              </p>
            )}
          </Box>
        </Box>
      </Box>
  );
}
