import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
} from "@mui/material";
export default function History() {
  const history: any = localStorage.history
    ? JSON.parse(localStorage.history)
    : undefined;
  function Icon(props: { url: string }) {
    const url = new URL(props.url);
    return (
      <img
        alt={url.hostname}
        src={`http://www.google.com/s2/favicons?domain=${url.hostname}`}
      />
    );
  }
  function Entry(props: { olink: string; slink: string }) {
    const { olink, slink } = props;
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <Icon url={olink} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={slink} secondary={olink} />
      </ListItem>
    );
  }
  return (
    <Box
      className="justify-center align-center flex flex-dir-column"
      sx={{
        bgcolor: "primary.dark",
      }}
    >
      <h1 className="white">History</h1>
      {history ? (
        <Paper className="overflow-auto m10 history-paper">
          <List
            className="fullwidth history-list"
            sx={{ bgcolor: "background.paper" }}
          >
            {Object.entries(history).map((item: any) => (
              <a href={item[1].slink}>
                <Entry olink={item[1].olink} slink={item[1].slink} />
              </a>
            ))}
          </List>
        </Paper>
      ) : (
        <h1 className="white">You haven't created any links.</h1>
      )}
    </Box>
  );
}
